'use strict';

angular.module('managerApp').config( ($routeProvider) => {
    $routeProvider.when('/packs', {
        templateUrl: 'app/packs/list.html',
        controller: 'PacksController',
        authenticate: 'user',
        hasPermissions: ['PACKS']
    });
});
